import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  [x: string]: any;

  constructor(private http: HttpClient) { }

  generateJwtByToken(token: string): Observable<any> {
    const headers = new HttpHeaders({ 'token': token });
    return this.http.post<{
      jwt: string,
      expiration: number,
      user_types: { label: string, redirection_url: string }[],
    }>(`${environment.api}/auth/token/validate`, {}, { headers: headers });
  }

  sendCode(mail: string): Observable<any> {
    return this.http.post<any>(`${environment.api}/recovery/password`, {
      email: mail,
    });
  }

  validateCode(mail: string, code: string): Observable<any> {
    return this.http.post<any>(`${environment.api}/recovery/validate/code`, {
      email: mail,
      code: code
    });
  }

  changePass(mail: string, code: string, pass: string): Observable<any> {
    return this.http.put<any>(`${environment.api}/recovery/change/password`, {
      email: mail,
      code: code,
      password: pass
    });
  }

  resendVerificationCode(email: string): Observable<any> {
    return this.http.post<any>(`${environment.api}/auth/resend/validate/email`, {
      email: email
    });
  }

}

export interface TokenDataWithRedirection {
  jwt: string;
  expiration: number;
  redirection_url: string;
}
