import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private readonly httpC: HttpClient) { }

  doLogin(data: {user: string, password: string}) {
    return this.httpC.post<{
      jwt: string,
      expiration: number,
      migrated:boolean,
      user_types: {label: string, redirection_url: string}[],
    }>(`${environment.api}/auth/login`, data);
  }

  refreshToken(jwt: string) {
    return this.httpC.post<{
      jwt: string,
      expiration: number
    }>(`${environment.api}/auth/refresher`, {jwt});
  }
}
