import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CodeRedirectionComponent } from './components/code-redirection/code-redirection.component';
import { LogoutComponent } from './components/logout/logout.component';

const routes: Routes = [
  {
    path: "",
    redirectTo: "login",
    pathMatch: "full"
  },
  {
    path: "login",
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule)
  },
  { path: 'code/:token', component: CodeRedirectionComponent },
  { path: 'logout', component: LogoutComponent },
  {
    path: "password-recover",
    loadChildren: () => import('./pages/password-recover/password-recover.module').then(m => m.PasswordRecoverModule)
  },
  {
    path: "password-change",
    loadChildren: () => import('./pages/password-change/password-change.module').then(m => m.PasswordChangeModule)
  },
  {
    path: "verify-code",
    loadChildren: () => import('./pages/verify-code/verify-code.module').then(m => m.VerifyCodeModule)
  },
  {
    path: "register",
    loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterModule)
  },
  {
    path: "unauthorized",
    loadChildren: () => import('./pages/unauthorized/unauthorized.module').then(m => m.UnauthorizedModule)
  },
  {
    path: "selection",
    loadChildren: () => import('./pages/selesion-destino/selesion.module').then(m => m.SelesionModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
