import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
})
export class LogoutComponent implements OnInit {

  constructor() {}

  ngOnInit(): void {
    // Limpia el local storage
    localStorage.clear();

    // Redirecciona al usuario a donde necesites después del deslogueo
    // Por ejemplo, a la página de inicio de sesión
    window.location.href = '/login'; // Actualiza esto según tus necesidades
  }
}
