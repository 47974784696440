import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-code-redirection',
  templateUrl: './code-redirection.component.html',
})
export class CodeRedirectionComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private readonly router: Router,
    private authService: AuthService,
    private readonly appSrv: AppService
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      const token = params.get('token');
      if (token) {
        this.validateAndRedirect(token);
      }
    });
  }

  private validateAndRedirect(token: string): void {
    this.authService.generateJwtByToken(token).subscribe({
      next: (data) => {
        this.appSrv.setSession(data);
        this.appSrv.validateAndRedirectGET().then(authorized => {
          if(!authorized) {
            this.router.navigate(['/unauthorized']);
          }
        });
      },
      error: (err) => {
        console.error(err);
        // Manejar error o redirigir a una página de error
      }
    });
  }
}
